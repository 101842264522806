export default {
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  logoLink: {
    float: 'left',
    height: '100%'
  }
};
