/* Imports */
import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

/**
 * Avatar contains the styles to override default Mui Avatar and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiAvatar & MuiAvatarGroup
 */

export default function Avatar(theme: Theme): any {
  /* Output */
  return {
    MuiAvatar: {
      styleOverrides: {
        root: {
          ...theme.typography.h5,
          textDecoration: 'none',
          width: 30,
          height: 30
        },
        colorDefault: {
          color: theme.palette.grey[600],
          backgroundColor: theme.palette.grey[300]
        }
      }
    }
  };
}
