/* Imports */
import { memo, useContext } from 'react';
import { Avatar } from '@mui/material';

/* Relative Imports */
import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * Component to show logged in user's profile picture.
 *
 * @component
 * @returns {JSX.Element}
 */
const MyAvatar = ({ ...other }): JSX.Element => {
  /* Hooks */
  const { user } = useContext(SessionContext);

  /* Output */
  return <Avatar sizes="20" src={user?.profile_photo} alt="" {...other} />;
};

export default memo(MyAvatar);
