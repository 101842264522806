/**
 * IconButton contains the styles to override default Mui IconButton and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiIconButton
 */
export default function IconButton(): any {
  /* Output */
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          '&:hover': {
            background: 'none'
          }
        }
      }
    }
  };
}
