/* Imports */
import React from 'react';
import { googleLogout } from '@react-oauth/google';

/* Imports */
import { ROOT_PATH } from 'routes/paths';
import {
  getAccessToken,
  isValidToken,
  removeAccessToken,
  setAccessToken
} from 'helper/authHelper';
// import { getUserProfileRequest } from 'services/auth/auth';
import { config } from 'config';

// ----------------------------------------------------------------------

/* Types/Interfaces */
/**
 * Interface used for session state.
 *
 * @interface ISessionState
 * @property {boolean} isAuthenticated - is authenticated for session state.
 * @property {string|null} authToken - authToken for session state.
 * @property {any|null} user - user for session state.
 * @property {func} LoginUser - login user function for session state.
 * @property {func} LogoutUser - logout user function for session state.
 * @property {func} setUser - set user function for session state.
 * @property {func} updateProfilePicture - set profile picture function of user for session state.
 */
export interface ISessionState {
  isAuthenticated: boolean;
  isPageLoaded: boolean | null;
  authToken: string | null;
  user: any | null;
  LoginUser: (token: string, loggedInUser: any, rememberMe: boolean) => void;
  LogoutUser: () => void;
  setUser: (loggedInUser: any) => void;
  updateProfilePicture: (profilephoto: string) => void;
}

/**
 * Interface used to define session provider.
 *
 * @interface ISessionProps
 * @property {node} children - contains data or component.
 */
export interface ISessionProps {
  children: React.ReactNode;
}

// ----------------------------------------------------------------------

/* Initial State */
const initialState: ISessionState = {
  isAuthenticated: false,
  isPageLoaded: null,
  authToken: null,
  user: null,
  LoginUser: async () => {},
  LogoutUser: () => {},
  setUser: () => {},
  updateProfilePicture: (profilephoto: string) => {}
};

/* Create Context */
const SessionContext = React.createContext<ISessionState>(initialState);

// ----------------------------------------------------------------------

class Session extends React.Component<ISessionProps, ISessionState> {
  static navigateToNotFoundPage(): void {
    if (window.location.pathname !== '/not-found') {
      window.location.href = '/not-found';
    }
  }

  /* Constructor */
  constructor(props: ISessionProps) {
    super(props);

    const accessToken: any = getAccessToken();
    const user = isValidToken(accessToken);

    this.state = {
      isAuthenticated: Boolean(accessToken && user),
      isPageLoaded: window.location.host === config.app.appHostName || null,
      authToken: accessToken,
      user,
      LoginUser: (token, loggedInUser, rememberMe) => {
        setAccessToken(token, rememberMe);
        console.log(token, loggedInUser, rememberMe);
        this.setState({
          isAuthenticated: true,
          isPageLoaded: true,
          authToken: token,
          user: { ...loggedInUser }
        });
      },
      LogoutUser: () => {
        removeAccessToken();
        URL.revokeObjectURL(this.state.user.profilePic);
        this.setState((prevState) => ({
          ...prevState,
          isAuthenticated: false,
          authToken: null,
          user: null
        }));
        googleLogout();

        window.location.href = ROOT_PATH;
      },
      setUser: (loggedInUser) => {
        this.setState({ user: loggedInUser });
      },
      updateProfilePicture: (profilePicture) => {
        this.setState((prevState) => ({
          ...prevState,
          user: {
            ...prevState.user,
            profile_photo: profilePicture
          }
        }));
      }
    };
  }

  componentDidMount(): void {
    console.log(',,,,', this.state.authToken, this.state.user);
    if (this.state.authToken && this.state.user) {
      this.getUserProfile();
    }
  }

  async getUserProfile(): Promise<void> {
    const response = {
      user: {
        first_name: this.state.user.given_name,
        last_name: this.state.user.family_name,
        email: this.state.user.email,
        profile_photo: this.state.user.picture,
        profilePic: this.state.user.picture
      }
    };
    this.setState((prevState) => ({
      ...prevState,
      user: { ...response.user }
    }));

    // try {
    //   const response = await getUserProfileRequest(this.state.authToken || '');
    //   console.log(response);
    //   if (response?.status.response_code === 200 && response?.user) {
    //     this.setState((prevState) => ({
    //       ...prevState,
    //       user: { ...response.user }
    //     }));
    //   }
    // } catch {
    //   const user = isValidToken(this.state.authToken || '');
    //   if (user) {
    //     this.setState((prevState) => ({
    //       ...prevState,
    //       user
    //     }));
    //   } else {
    //     this.state.LogoutUser();
    //   }
    // }
  }

  /* Output */
  render(): JSX.Element {
    return (
      <SessionContext.Provider value={this.state}>
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}

export default SessionContext;
export const SessionProvider = Session;
export const SessionConsumer = SessionContext.Consumer;
