/* Imports */
import { lazy } from 'react';

/* Relative Imports */
import AuthLayout from 'layout/AuthLayout';

/* Local Imports */
import { PAGE_AUTH, ROOT_PATH } from './paths';
import UserGuard from './guards/UserGuard';

// ----------------------------------------------------------------------

/* Auth Module Imports */
const SignInPage = lazy(() => import('views/auth/SignIn'));

const NotFoundPage = lazy(() => import('views/page-not-found'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const AuthRoutes: Array<object> = [
  {
    path: ROOT_PATH,
    element: (
      <UserGuard>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UserGuard>
    )
  },
  {
    path: PAGE_AUTH.signIn.relativePath,
    element: (
      <UserGuard>
        <AuthLayout>
          <SignInPage />
        </AuthLayout>
      </UserGuard>
    )
  }
];

/**
 * assign component to no found routes
 *
 * @return {array}
 */
export const NotFoundRoutes: Array<object> = [
  {
    path: '*',
    element: <NotFoundPage />
  }
];

export default AuthRoutes;
