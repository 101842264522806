/* Imports */
import { memo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

/* Relative Imports */
import AccountPopover from 'components/AccountPopover';
import { fonts } from 'theme/typography';

/* Local Imports */
import styles from '../index.style';

// ----------------------------------------------------------------------

/**
 * Header for the dashboard pages
 *
 * @component
 * @returns {JSX.Element}
 */
const Header = (): JSX.Element => {
  /* Hooks */
  const theme = useTheme();

  /* Output */
  return (
    <Box sx={styles.header}>
      <Box
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Box
          component="img"
          alt="logo"
          src={'/assets/images/doss-logo-icon.png'}
          sx={styles.logo}
        />
        <Typography
          variant="h4"
          style={{
            fontFamily: fonts.ClashDisplay_Variable,
            fontWeight: '500',
            color: theme.palette.text.primary,
            marginLeft: 4,
            marginBottom: -6
          }}
        >
          {'Floc / Friendly Fire'}
        </Typography>
      </Box>

      <Box mr={1} sx={styles.rightOptions}>
        <AccountPopover />
      </Box>
    </Box>
  );
};

export default memo(Header);
