export default {
  accountPopover: {
    width: 230
  },
  popoverProfile: {
    my: 1.5,
    px: 2.5,
    flexWrap: 'nowrap'
  },
  popoverMenuItem: {
    typography: 'body2',
    py: 1,
    px: 2.5
  },
  modeMenuItem: {
    cursor: 'default',
    '&:hover': {
      background: 'none'
    }
  },
  profileDivider: {
    my: 1
  },
  logoutDivider: {
    marginBottom: '0px !important'
  },
  logout: {
    width: '100%',
    justifyContent: 'center'
  }
};
