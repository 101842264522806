/* Local Store Keys */
const themeMode = 'themeMode';
const aptosAccount = '@aptos/account';
const aptosEphemeralKeyPair = '@aptos/ekp';

/* Getter */
export const getThemeMode = () => {
  return localStorage.getItem(themeMode);
};

export const getAptosAccount = () => {
  return localStorage.getItem(aptosAccount);
};

export const getAptosEphemeralKeyPair = () => {
  return localStorage.getItem(aptosEphemeralKeyPair);
};

/* Setter */
export const setThemeMode = (themeModeVal: any) => {
  localStorage.setItem(themeMode, themeModeVal);
};

export const setAptosAccount = (aptosAccountVal: any) => {
  localStorage.setItem(aptosAccount, aptosAccountVal);
};

export const setAptosEphemeralKeyPair = (aptosEphemeralKeyPairVal: any) => {
  localStorage.setItem(aptosEphemeralKeyPair, aptosEphemeralKeyPairVal);
};
