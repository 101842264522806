/* Imports */
import { Suspense, useContext, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';

/* Relative Imports */
import LoadingScreen from 'components/LoadingScreen';
import SessionContext from 'context/SessionContext';

/* Local Imports */
import AuthRoutes, { NotFoundRoutes } from './AuthRoutes';
import AppRoutes from './AppRoutes';

// ----------------------------------------------------------------------

/* Merge Routes */
/**
 * Create routing with the routes
 *
 * @return {JSX.Element}
 */
const Routing: React.FC = (): JSX.Element => {
  const [routes, setRoutes] = useState([...AuthRoutes, ...NotFoundRoutes]);
  const content = useRoutes(routes);
  const { isAuthenticated, user } = useContext(SessionContext);

  useEffect(() => {
    const newRoutes: any = [];
    if (isAuthenticated && user) {
      newRoutes.push(...AppRoutes);
    }
    setRoutes([...AuthRoutes, ...NotFoundRoutes, ...newRoutes]);
  }, [isAuthenticated]);

  return <Suspense fallback={<LoadingScreen />}>{content}</Suspense>;
};

export default Routing;
