/* Imports */
import { Theme } from '@mui/material';

// ----------------------------------------------------------------------

/**
 * Typography contains the styles to override default Mui Typography and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiTypography
 */
export default function Typography(theme: Theme): any {
  return {
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: theme.spacing(2)
        },
        gutterBottom: {
          marginBottom: theme.spacing(1)
        }
      }
    }
  };
}
