/* Imports */
import CancelIcon from '@mui/icons-material/Cancel';

// ----------------------------------------------------------------------

/**
 * Chip contains the styles to override default Mui Chip and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiChip
 */
export default function Chip(theme: any): any {
  /* Output */
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: () => CancelIcon
      },

      styleOverrides: {
        root: {
          borderRadius: 4
        },
        label: {
          // marginTop: -4
        },
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary
          }
        },
        outlined: {
          borderColor: theme.palette.grey[500_32],
          '&.MuiChip-colorPrimary': {
            borderColor: theme.palette.primary.main
          }
        }
      }
    }
  };
}
