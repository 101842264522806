// Mixpanel Events

/* Page */
export const PAGE_OPEN = 'PAGE_OPEN';
export const PAGE_CLOSED = 'PAGE_CLOSED';

/* Sign In */
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';

/* Bottom Nav */
export const HOME_PAGE_CLICKED = 'HOME_PAGE_CLICKED';
export const EXPLORE_PAGE_CLICKED = 'EXPLORE_PAGE_CLICKED';
export const PROFILE_PAGE_CLICKED = 'PROFILE_PAGE_CLICKED';

/* Contest Tabs */
export const ALL_CONTEST_UPCOMING_CLICKED = 'ALL_CONTEST_UPCOMING_CLICKED';
export const ALL_CONTEST_LIVE_CLICKED = 'ALL_CONTEST_LIVE_CLICKED';
export const ALL_CONTEST_COMPLETED_CLICKED = 'ALL_CONTEST_COMPLETED_CLICKED';

/* Creator Tabs */
export const DETAILS_TOP_CREATOR_CLICKED = 'DETAILS_TOP_CREATOR_CLICKED';
export const DETAILS_LIVE_FEED_CLICKED = 'DETAILS_LIVE_FEED_CLICKED';
export const DETAILS_TRADING_FEED_CLICKED = 'DETAILS_TRADING_FEED_CLICKED';
export const DETAILS_COMMUNITY_CLICKED = 'DETAILS_COMMUNITY_CLICKED';

/* Submit Deck */
export const COMINGUP_CONTEST_SUBMIT_CLICKED =
  'COMINGUP_CONTEST_SUBMIT_CLICKED';
export const CREATOR_ADDED = 'CREATOR_ADDED';
export const CREATOR_REMOVED = 'CREATOR_REMOVED';
export const TEAM_SELECTION_SUBMITED_DECK = 'TEAM_SELECTION_SUBMITED_DECK';

/* Explore */
export const EXPLORE_PAGE_FILTER_SELECT_LEVEL =
  'EXPLORE_PAGE_FILTER_SELECT_LEVEL';
export const EXPLORE_PAGE_PRIZE_SORTED = 'EXPLORE_PAGE_PRIZE_SORTED';
export const EXPLORE_CREATOR_CARD_CLICKED = 'EXPLORE_CREATOR_CARD_CLICKED';

/* Creator Details */
export const CREATOR_DETAILS = 'CREATOR_DETAILS';
export const CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_AFTER_VIDEO_UPLOAD =
  'CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_AFTER_VIDEO_UPLOAD';
export const CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_6_HOURS =
  'CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_6_HOURS';
export const CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_12_HOURS =
  'CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_12_HOURS';
export const CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_24_HOURS =
  'CREATOR_DETAILS_LATEST_VIDEO_ANALYSIS_FIRST_24_HOURS';
export const CREATOR_DETAILS_LOAD_MORE_CLICKED =
  'CREATOR_DETAILS_LOAD_MORE_CLICKED';
export const CREATOR_DETAILS_BUY_CLICKED = 'CREATOR_DETAILS_BUY_CLICKED';
export const CREATOR_DETAILS_BID_CLICKED = 'CREATOR_DETAILS_BID_CLICKED';
export const CREATOR_DETAILS_SELL_CLICKED = 'CREATOR_DETAILS_SELL_CLICKED';
export const CREATOR_DETAILS_SIGN_TRANSACTION_CLICKED =
  'CREATOR_DETAILS_SIGN_TRANSACTION_CLICKED';
export const CREATOR_DETAILS_TRANSACTION_SUCCESS =
  'CREATOR_DETAILS_TRANSACTION_SUCCESS';

/* Profile */
export const PROFILE_PAGE_CARD_CLICKED = 'PROFILE_PAGE_CARD_CLICKED';
export const PROFILE_PAGE_POWERUPS = 'PROFILE_PAGE_POWERUPS';
export const PROFILE_PAGE_TRANSACTION = 'PROFILE_PAGE_TRANSACTION';
export const PROFILE_PAGE_LOAD_WALLET = 'PROFILE_PAGE_LOAD_WALLET';
export const PROFILE_PAGE_MERGE_CARDS = 'PROFILE_PAGE_MERGE_CARDS';
export const PROFILE_PAGE_CREATOR_CARD_CLICKED =
  'PROFILE_PAGE_CREATOR_CARD_CLICKED';
