/* Imports */
import { AccountTree as AccountTreeIcon } from '@mui/icons-material';

/* Relative Imports */
import { PAGE_APP } from 'routes/paths';

// ----------------------------------------------------------------------

/* Side bar tabs */
const SidebarConfig = [
  {
    title: 'My Profile',
    href: PAGE_APP.profile.absolutePath,
    icon: AccountTreeIcon
  }
];

export default SidebarConfig;
