/**
 * Style object for auth pages/components
 *
 * @returns {object}
 */

export default {
  bottomDialog: (theme) => ({
    '& .MuiDialog-container': {
      alignItems: 'flex-end',
      '& .MuiDialog-paper': {
        m: 0,
        backgroundColor: '#FEFEFE',
        color: theme.palette.common.black,
        '&.MuiPaper-rounded': {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        },
        '& .MuiDialogTitle-root': {
          ...theme.typography.h6,
          fontWeight: theme.typography.fontWeightMedium,
          backgroundColor: '#FBEB94',
          color: theme.palette.common.black,
          textAlign: 'center',
          p: 1.75
        },
        '& .MuiDialogContent-root': {
          py: '20px !important',
          px: '12px !important'
        }
      }
    }
  })
};
