/* Imports */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { Home, Storefront, Wallet } from '@mui/icons-material';

/* Relative Imports */
import Footer from 'components/Footer';
import { PAGE_APP } from 'routes/paths';
import mixpanel from 'mixpanel/mixpanel';
import {
  EXPLORE_PAGE_CLICKED,
  HOME_PAGE_CLICKED,
  PROFILE_PAGE_CLICKED
} from 'mixpanel/mixpanelText';

/* Local Imports */
import { Header } from './components';
import styles from './index.style';

// ----------------------------------------------------------------------

/* Types/Interfaces */
/**
 * Interface used to create outer design layout for all dashboard pages.
 *
 * @interface CompanyDashboardLayoutProps
 * @property {node} children - contains the child components.
 */
export interface DashboardLayoutProps {
  children: React.ReactNode;
}

// ----------------------------------------------------------------------

/**
 * Outer design layout for all dashboard pages
 *
 * @component
 * @param {node} children - contains the child components
 */
const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children
}): JSX.Element => {
  /* Hooks */
  const { pathname } = useLocation();

  /* States */
  const [value, setValue] = useState(pathname);

  /* Side-Effect */
  useEffect(() => {
    setValue(pathname);
  }, [pathname]);
  /* Output */
  return (
    <Box sx={styles.rootStyle}>
      <Box sx={styles.wrapperStyle}>
        <Header />
        <Box sx={styles.containerStyle}>{children}</Box>
        <Box>
          <BottomNavigation
            // showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);

              if (newValue === PAGE_APP.root.absolutePath) {
                mixpanel.track(HOME_PAGE_CLICKED);
              } else if (newValue === PAGE_APP.explore.absolutePath) {
                mixpanel.track(EXPLORE_PAGE_CLICKED);
              } else if (newValue === PAGE_APP.profile.absolutePath) {
                mixpanel.track(PROFILE_PAGE_CLICKED);
              }
            }}
          >
            <BottomNavigationAction
              value={PAGE_APP.root.absolutePath}
              label="Home"
              icon={<Home />}
              to={PAGE_APP.root.absolutePath}
              component={RouterLink}
            />
            <BottomNavigationAction
              value={PAGE_APP.explore.absolutePath}
              label="Explore"
              icon={<Storefront />}
              to={PAGE_APP.explore.absolutePath}
              component={RouterLink}
            />
            <BottomNavigationAction
              value={PAGE_APP.profile.absolutePath}
              label="Profile"
              icon={<Wallet />}
              to={PAGE_APP.profile.absolutePath}
              component={RouterLink}
            />
          </BottomNavigation>
        </Box>
        <Footer containerStyle={styles.footer} />
      </Box>
    </Box>
  );
};

export default DashboardLayout;
