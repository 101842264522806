/* Imports */
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/* Relative Imports */
import DashboardLayout from 'layout/DashboardLayout';

/* Local Imports */
import { PAGE_APP } from './paths';
import AuthGuard from './guards/AuthGuard';

// ----------------------------------------------------------------------

const Home = lazy(() => import('views/app/Home'));
const Profile = lazy(() => import('views/app/Profile'));
const Explore = lazy(() => import('views/app/Explore'));
const Contest = lazy(() => import('views/app/Contest'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const AppRoutes: Array<object> = [
  {
    path: PAGE_APP.root.relativePath,
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Home />
      },
      // {
      //   path: PAGE_APP.root.relativePath,
      //   element: <Home />
      // },
      // {
      //   path: PAGE_APP.profile.absolutePath,
      //   element: <Profile />
      // },
      {
        path: PAGE_APP.profile.relativePath,
        element: <Profile />
      },
      // {
      //   path: PAGE_APP.explore.absolutePath,
      //   element: <Explore />
      // },
      {
        path: PAGE_APP.explore.relativePath,
        element: <Explore />
      },
      {
        path: PAGE_APP.contest.relativePath,
        element: <Contest />
      }
    ]
  }
];

export default AppRoutes;
