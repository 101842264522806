/* Constants */
const breakpoints: any = {
  values: {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
    laptop: 1280
  }
};

export default breakpoints;
