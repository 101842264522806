/**
 * Paper contains the styles to override default Mui Paper and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiPaper
 */
export default function Paper(): any {
  /* Output */
  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0
      },

      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  };
}
