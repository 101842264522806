/**
 * Button contains the styles to override default Mui Button and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiButton
 */
export default function Button(theme: any): any {
  /* Output */
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0.5,
          textTransform: 'none',
          textAlign: 'center',
          padding: theme.spacing(0.5, 1),
          '&:hover': {
            boxShadow: 'none'
          }
        },
        containedSizeLarge: {
          height: 48
        },
        containedSizeMedium: {
          height: 36
        },
        containedSizeSmall: {
          height: 28
        },
        outlinedSizeLarge: {
          height: 48
        },
        outlinedSizeMedium: {
          height: 36
        },
        outlinedSizeSmall: {
          height: 28
        },
        containedSecondary: {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.grey[50],
          border: `1px solid ${theme.palette.grey[50]}`,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark
          }
        },
        containedInherit: {
          color: theme.palette.grey[800],
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        text: {
          padding: 0,
          [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0
          },
          '&:hover': {
            background: 'none'
          }
        }
      }
    }
  };
}
