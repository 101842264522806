/**
 * LoadingButton contains the styles to override default Mui LoadingButton and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiLoadingButton
 */
export default function LoadingButton(): any {
  /* Output */
  return {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-text': {
            '& .MuiLoadingButton-startIconPendingStart': {
              marginLeft: 0
            },
            '& .MuiLoadingButton-endIconPendingEnd': {
              marginRight: 0
            }
          }
        }
      }
    }
  };
}
