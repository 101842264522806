/* Imports */
import React, { useContext } from 'react';
import { Box } from '@mui/material';

/* Relative Imports */
import Footer from 'components/Footer';
import WebsiteLogo from 'components/WebsiteLogo';
import SessionContext from 'context/SessionContext';

/* Local Imports */
import styles from './index.style';
import './signin.css';

// ----------------------------------------------------------------------

/* Types/Interfaces */
/**
 * Interface used to create outer design layout for all auth pages.
 *
 * @interface AuthLayoutProps
 * @property {node} children - contains the child components.
 */
export interface AuthLayoutProps {
  children: React.ReactNode;
}

// ----------------------------------------------------------------------

/**
 * Outer design layout for all auth pages
 *
 * @component
 * @param {node} children - contains the child components
 */
const AuthLayout: React.FC<AuthLayoutProps> = ({ children }): JSX.Element => {
  /* Hooks */
  const { isPageLoaded } = useContext(SessionContext);

  /* Output */
  return isPageLoaded ? (
    <Box sx={styles.wrapperStyle}>
      <div className='signin-container' >
        <Box sx={styles.formStyle}>{children}</Box>
      </div>
      <Footer containerStyle={styles.footer} />
    </Box>
  ) : (
    <></>
  ); 
};

export default AuthLayout;
