/* Imports */
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/* Relative Imports */
import { PAGE_APP } from 'routes/paths';
import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/* Types/Interfaces */
/**
 * Interface used to create component to define protectation layout for pages, which are not accessible after login.
 *
 * @interface UserGuardProps
 * @property {node} children - contains the child components.
 */
export interface UserGuardProps {
  children: React.ReactElement;
}

// ----------------------------------------------------------------------

/**
 * Component to define protectation layout for pages, which are not accessible after login
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
const UserGuard: React.FC<UserGuardProps> = ({ children }): JSX.Element => {
  /* Hooks */
  const context = useContext(SessionContext);
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnurl');
  console.log(context.isAuthenticated, 'qwertyuio');
  /* Output */
  if (context.isAuthenticated) {
    const redirectPath = PAGE_APP.root.absolutePath;
    return <Navigate to={returnUrl ?? redirectPath} state={location.state} />;
  }

  return children;
};

export default UserGuard;
