export const config = {
  app: {
    appVersion: process.env.REACT_APP_VERSION,
    appHostName: process.env.REACT_APP_HOST_NAME
  },
  api: {
    apiBaseUrl: process.env.REACT_APP_API_BASEURL
  },
  keys: {
    privateKey: process.env.REACT_APP_PRIVATE_KEY,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN
  },
  auth: {
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
  },
  aptos: {
    networkType: process.env.REACT_APP_APTOS_NETWORK_TYPE,
    resourceAddress: process.env.REACT_APP_APTOS_RESOURCE_ADDRESS
  },
  base: {
    baseUrlContest: process.env.REACT_APP_BASE_URL_CONTEST,
    baseUrlWallet: process.env.REACT_APP_BASE_URL_WALLET
  }
};
