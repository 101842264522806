/* Imports */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';

/* Relative Imports */
import ThemeConfig from 'theme';
import Routing from 'routes';
import { config } from 'config';
import { SessionProvider } from 'context/SessionContext';
import { ThemeContextProvider } from 'context/ThemeContext';
import ScrollToTop from 'components/ScrollToTop';
import NotistackProvider from 'components/NotistackProvider';
import ThemeModeSetting from 'components/ThemeModeSetting';

// ----------------------------------------------------------------------

/**
 * App component to to set all the higher level components and routes.
 *
 * @component
 * @returns {JSX.Element}
 */
const App: React.FC = (): JSX.Element => {
  return (
    <GoogleOAuthProvider clientId={config.auth.googleClientId as string}>
      <HelmetProvider>
        <ThemeContextProvider>
          <ThemeConfig>
            <SessionProvider>
              <NotistackProvider>
                {/* <ThemeModeSetting /> */}
                <Router>
                  <ScrollToTop />
                  <Routing />
                </Router>
              </NotistackProvider>
            </SessionProvider>
          </ThemeConfig>
        </ThemeContextProvider>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
