/* Imports */
import React from 'react';
import { getThemeMode, setThemeMode } from 'store/localStore';

// ----------------------------------------------------------------------

/* Types/Interfaces */
/**
 * Interface used for theme mode state.
 *
 * @interface IThemeContextState
 * @property {string} themeMode - theme mode state.
 * @property {func} switchMode - switch mode function for theme state.
 */
export interface IThemeContextState {
  themeMode: string;
  switchMode: (mode: string) => void;
}

/**
 * Interface used to define theme mode provider.
 *
 * @interface IThemeContextProps
 * @property {node} children - contains the child components.
 */
export interface IThemeContextProps {
  children: React.ReactNode;
}

// ----------------------------------------------------------------------

/* Initial State */
const initialState: IThemeContextState = {
  themeMode: 'dark',
  switchMode: () => null
};

/* Create Context */
const ThemeContext = React.createContext<IThemeContextState>(initialState);

// ----------------------------------------------------------------------

/**
 * Component to define theme mode provider
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
class ThemeContextProvider extends React.Component<
  IThemeContextProps,
  IThemeContextState
> {
  /* Constructor */
  constructor(props: IThemeContextProps) {
    super(props);
    let selectedThemeMode = 'dark';

    if (!selectedThemeMode) {
      setThemeMode('dark');
    }

    this.state = {
      themeMode: selectedThemeMode,
      switchMode: (newMode: string) => {
        this.setState((prevState) => ({
          ...prevState,
          themeMode: 'dark'
        }));
        setThemeMode('dark');
      }
    };
  }

  /* Output */
  render(): JSX.Element {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}

export default ThemeContext;
export { ThemeContextProvider };
