/* Constants */
const ROOT_PATH = '/';
const ROOT_APP = 'home';

/* Home Page */
export { ROOT_PATH };

/* Auth Pages */
export const PAGE_AUTH = {
  signIn: {
    relativePath: 'signin',
    absolutePath: '/signin'
  },
  notFound: {
    relativePath: 'not-found',
    absolutePath: '/not-found'
  }
};

/* App Pages */
export const PAGE_APP = {
  root: {
    relativePath: ROOT_APP,
    absolutePath: `/${ROOT_APP}`
  },
  profile: {
    relativePath: 'profile',
    absolutePath: `/${ROOT_APP}/profile`
  },
  explore: {
    relativePath: 'explore',
    absolutePath: `/${ROOT_APP}/explore`
  },
  contest: {
    relativePath: 'contest/:addr',
    absolutePath: `/${ROOT_APP}/contest/`
  }
};
