/* Relative Imports */
import { pxToRem } from 'util/helperFunctions';

// ----------------------------------------------------------------------

type FontSizeMeasurement = {
  sm: number;
  md: number;
  lg: number;
};

// ----------------------------------------------------------------------

/**
 * To set fontsize according to the width of the screen.
 *
 * @param sm - small font size
 * @param md - medium font size
 * @param lg - large font size
 * @returns font size based on the width of the screen
 */
function responsiveFontSizes({ sm, md, lg }: FontSizeMeasurement): any {
  return {
    '@media (min-width:768px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:1024px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1440px)': {
      fontSize: pxToRem(lg)
    }
  };
}

/* Constants */
export const fonts: any = {
  ClashDisplay_Regular: 'ClashDisplay-Regular',
  ClashDisplay_Medium: 'ClashDisplay-Medium',
  ClashDisplay_SemiBold: 'ClashDisplay-Semibold',
  ClashDisplay_Bold: 'ClashDisplay-Bold',
  ClashDisplay_Light: 'ClashDisplay-Light',
  ClashDisplay_ExtraLight: 'ClashDisplay-Extralight',
  ClashDisplay_Variable: 'ClashDisplay-Variable'
};

const typography: any = {
  fontFamily: fonts.ClashDisplay_Regular,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fonts,
  h1: {
    fontFamily: fonts.ClashDisplay_Bold,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
  },
  h2: {
    fontFamily: fonts.ClashDisplay_Bold,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
  },
  h3: {
    fontFamily: fonts.ClashDisplay_SemiBold,
    fontWeight: 700,
    lineHeight: 1,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
  },
  h4: {
    fontFamily: fonts.ClashDisplay_Regular,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
  },
  h5: {
    fontFamily: fonts.ClashDisplay_Regular,
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
  },
  h6: {
    fontFamily: fonts.ClashDisplay_Regular,
    fontWeight: 400,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
  },
  subtitle1: {
    fontFamily: fonts.ClashDisplay_SemiBold,
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  subtitle2: {
    fontFamily: fonts.ClashDisplay_Medium,
    fontWeight: 500,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14)
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16)
  },
  body2: {
    lineHeight: 1.5,
    fontSize: pxToRem(14)
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12)
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  button: {
    fontWeight: 'normal',
    lineHeight: 1.2,
    fontSize: pxToRem(14),
    textTransform: 'capitalize'
  }
};

export default typography;
