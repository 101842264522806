/* Imports */
import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

/**
 * Component scrolls to top when path is changed.
 *
 * @component
 */
const ScrollToTop = (): null => {
  /* Hooks */
  const { pathname } = useLocation();

  /* Side-Effects */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  /* Output */
  return null;
};

export default memo(ScrollToTop);
