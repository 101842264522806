/* Imports */
import { memo } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// --------------------------------------------------------------------------------------------

/**
 *
 * Handles rendering of 'bottom dialog' component.
 *
 * @component
 * @param {node} props - drilled down props
 * @param
 *
 * @returns {JSX.Element}
 */
const BottomDialog = ({ open, title, onCancel, children }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onCancel}
      sx={styles.bottomDialog}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default memo(BottomDialog);
