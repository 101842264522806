/* Imports */
import { useCallback, useContext } from 'react';

/* Relative Imports */
import ThemeContext from 'context/ThemeContext';

// ----------------------------------------------------------------------

/**
 * Hook to get/set the theme mode
 * @component
 * @yields {function}
 */
export default function useSettings(): any {
  /* Hooks */
  const { themeMode, switchMode } = useContext(ThemeContext);

  /**
   * function to change the theme mode
   *
   * @returns {void}
   */
  const handleChangeTheme = useCallback(() => {
    switchMode('dark');
  }, [themeMode]);

  /* Output */
  return {
    themeMode,
    switchMode: handleChangeTheme
  };
}
