/* Imports */
import { replace } from 'lodash';
import { format } from 'date-fns';
import numeral from 'numeral';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fCurrency(number: number): string {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number: number): string {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: number): string {
  return numeral(number).format();
}

export function fShortenNumber(number: number): string {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number: number): string {
  return numeral(number).format('0.0 b');
}

/**
 * function to format the date
 *
 * @param {Date | string} date - date to convert in specific format
 * @param {string} dateFormat - date format to convert the date
 * @returns {string}
 */
export function getDate(
  date: Date | string,
  dateFormat: string = 'dd MMM yyyy'
): string | undefined {
  if (date) {
    return format(new Date(date), dateFormat);
  }
}

/**
 * function to convert font size from rem to px
 *
 * @param {string} value - font size value in rem
 * @returns {number}
 */
export function remToPx(value: string): number {
  return Math.round(parseFloat(value) * 16);
}

/**
 * function to convert font size from px to rem
 *
 * @param {number} value - font size value in px
 * @returns {string}
 */
export function pxToRem(value: number): string {
  return `${value / 16}rem`;
}

export default function formatNumber(number: number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  } else {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export const getFeedType = (type: number): string => {
  const feedType = [
    'NOTANY',
    'VIDEOUPLOAD',
    'RANK1',
    'RANK2',
    'RANK3',
    'RANKUP',
    'RANKDOWN',
    'CONTESTENDED',
    'CONTESTSTARTED'
  ];
  return feedType[type] || '';
};

export const getFeedButtonType = (type: number): string => {
  const feedButtonType = [
    'NOCLICK',
    'LIVECONTESTPAGE',
    'PASTCONTESTPAGE',
    'SHARE'
  ];
  return feedButtonType[type] || '';
};

export const getCreatorCategory = (key: number): any => {
  const statusIcon = {
    0: {
      name: '10M+',
      color: '#FE6363'
    },
    1: {
      name: '5M+',
      color: '#FED263'
    },
    2: {
      name: '3M+',
      color: '#4754F0'
    },
    3: {
      name: '2M+',
      color: '#FD9898'
    },
    4: {
      name: '<2M',
      color: '#D9D9D9'
    }
  };

  return (statusIcon as any)[key];
};

export const getDaysRange = (
  startDateInString: string,
  endDateInString: string
) => {
  let startDate = moment(
    new Date(
      startDateInString.substring(0, 10) +
        'T' +
        startDateInString.substring(11, 19) +
        '+00:00'
    )
  );

  let endDate = moment(
    new Date(
      endDateInString.substring(0, 10) +
        'T' +
        endDateInString.substring(11, 19) +
        '+00:00'
    )
  );
  return parseInt(
    moment.duration(endDate.diff(startDate)).asDays().toFixed(0)
  ) > 0
    ? moment.duration(endDate.diff(startDate)).asDays().toFixed(0) +
        `${
          moment.duration(endDate.diff(startDate)).asDays() >= 2
            ? ' Days'
            : ' Day'
        }`
    : moment.duration(endDate.diff(startDate)).asHours().toFixed(0) +
        `${
          moment.duration(endDate.diff(startDate)).asHours() >= 2
            ? ' Hours'
            : ' Hour'
        }`;
};

/**
 * function to remove item from array
 *
 * @param {array} itemArray - array of items to remove item
 * @param {string} match - object property to match with id
 * @param {number} id - item's id to find the index
 * @returns {array}
 */
export function removeItemFromArray(
  itemArray: Array<any>,
  match: string,
  id: number
): Array<any> {
  const index = itemArray?.findIndex((x) => x[match] === id);
  if (index > -1) {
    itemArray.splice(index, 1);
  }
  return itemArray;
}

/**
 * function to update item from array
 *
 * @param {array} itemArray - array of items to update item
 * @param {string} match - object property to match with id
 * @param {number} id - item's id to find the index
 * @param {string} fieldMatch - object property to match with field
 * @param {any} value - object property to update with value
 * @returns {array}
 */
export function updateItemFromArray(
  itemArray: Array<any>,
  match: string,
  id: number,
  fieldMatch: string,
  value: any
): Array<any> {
  itemArray?.map((item) => {
    if (item[match] === id) {
      item[fieldMatch] = value;
    }
    return item;
  });
  return itemArray;
}

const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
  btoa: (input: string = '') => {
    let str = input;
    let output = '';

    for (
      let block = 0, charCode, i = 0, map = chars;
      str.charAt(i | 0) || ((map = '='), i % 1);
      output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))
    ) {
      charCode = str.charCodeAt((i += 3 / 4));

      if (charCode > 0xff) {
        throw new Error(
          "'btoa' failed: The string to be encoded contains characters outside of the Latin1 range."
        );
      }

      block = (block << 8) | charCode;
    }

    return output;
  },

  atob: (input: string = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }
    for (
      let bc = 0, bs = 0, buffer, i = 0;
      (buffer = str.charAt(i++));
      ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};

export const convertHexToUtf8 = (hex: string): string => {
  // Remove the '0x' prefix if it exists
  const cleanedHex = hex.startsWith('0x') ? hex.slice(2) : hex;

  // Convert hex string to a byte array
  const bytes = [];
  for (let i = 0; i < cleanedHex.length; i += 2) {
    bytes.push(parseInt(cleanedHex.substr(i, 2), 16));
  }

  // Convert byte array to UTF-8 string
  const utf8Str = new TextDecoder().decode(new Uint8Array(bytes));
  return utf8Str;
};
