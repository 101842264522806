// Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';

import { config } from 'config';

const mixpanelToken = config.keys.mixpanelToken as string;

// init Mixpanel
mixpanel.init(mixpanelToken, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage'
});

export default mixpanel;
